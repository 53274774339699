* {
  box-sizing: border-box;
}

html, body, #app, div[data-reactroot] {
  height: 100%;
}


ul {
  padding: 0;
}

p {
  margin: 0;
}

span {
  pointer-events: none;
}


.score-container, .question-count {
  width: 250px;
  text-align: center;
}

.score-container h2, .question-count h2 {
  margin-bottom: 0;
  font-size: 2em;
  font-weight: 400;
}

.score, .question-number {
  font-size: 4em;
  font-weight: 100;
}

.description {
  font-size: 1.5em;
}

.questions {
  width: 100%;
  margin: 0px auto 0;
}

.question {
  font-size: 2em;
}

.question-answers {
  margin-top: .65em;
  padding-left: 1.2em;
}

.question-answer {
  list-style-type: lower-alpha;
  cursor: pointer;
  /*border: 5px solid transparent;*/
  padding: 8px;
  border-radius: 30px;
  font-size: 22px;
}

.question-answer span {
  line-height: 1.0;

}

.blink-1 {
  -webkit-animation: blink-1 0.6s 2.75s both;
  animation: blink-1 0.6s 2.75s both;
}

.tracking-in-contract {
  -webkit-animation: tracking-in-contract 0.8s cubic-bezier(0.215, 0.610, 0.355, 1.000) both;
  animation: tracking-in-contract 0.8s cubic-bezier(0.215, 0.610, 0.355, 1.000) both;
}

.roll-in-left {
  -webkit-animation: roll-in-left 0.6s ease-out both;
  animation: roll-in-left 0.6s ease-out both;
}

/* ----------------------------------------------
 * Generated by Animista on 2020-4-12 10:21:46
 * Licensed under FreeBSD License.
 * See http://animista.net/license for more info.
 * w: http://animista.net, t: @cssanimista
 * ---------------------------------------------- */

/**
 * ----------------------------------------
 * animation roll-in-left
 * ----------------------------------------
 */
@-webkit-keyframes roll-in-left {
  0% {
    -webkit-transform: translateX(-800px) rotate(-540deg);
    transform: translateX(-800px) rotate(-540deg);
    opacity: 0;
  }
  100% {
    -webkit-transform: translateX(0) rotate(0deg);
    transform: translateX(0) rotate(0deg);
    opacity: 1;
  }
}
@keyframes roll-in-left {
  0% {
    -webkit-transform: translateX(-800px) rotate(-540deg);
    transform: translateX(-800px) rotate(-540deg);
    opacity: 0;
  }
  100% {
    -webkit-transform: translateX(0) rotate(0deg);
    transform: translateX(0) rotate(0deg);
    opacity: 1;
  }
}

@keyframes slit-in-diagonal-2 {
  0% {
    -webkit-transform: translateZ(-800px) rotate3d(-1, 1, 0, -90deg);
    transform: translateZ(-800px) rotate3d(-1, 1, 0, -90deg);
    -webkit-animation-timing-function: ease-in;
    animation-timing-function: ease-in;
    opacity: 0;
  }
  54% {
    -webkit-transform: translateZ(-160px) rotate3d(-1, 1, 0, -87deg);
    transform: translateZ(-160px) rotate3d(-1, 1, 0, -87deg);
    -webkit-animation-timing-function: ease-in-out;
    animation-timing-function: ease-in-out;
    opacity: 1;
  }
  100% {
    -webkit-transform: translateZ(0) rotate3d(-1, 1, 0, 0);
    transform: translateZ(0) rotate3d(-1, 1, 0, 0);
    -webkit-animation-timing-function: ease-out;
    animation-timing-function: ease-out;
  }
}

@-webkit-keyframes tracking-in-contract {
  0% {
    letter-spacing: 1em;

    opacity: 0;
  }
  60% {
    opacity: 0.6;
  }
  100% {
   letter-spacing: normal;

    opacity: 1;
  }
}
@keyframes tracking-in-contract {
  0% {
    letter-spacing: 1em;
    opacity: 0;
  }
  60% {
    opacity: 0.6;
  }
  100% {
    letter-spacing: normal;
    opacity: 1;
  }
}



/**
 * ----------------------------------------
 * animation blink-1
 * ----------------------------------------
 */
@-webkit-keyframes blink-1 {
  0%,
  50%,
  100% {
    opacity: 1;
  }
  25%,
  75% {
    opacity: 0;
  }
}
@keyframes blink-1 {
  0%,
  50%,
  100% {
    opacity: 1;
  }
  25%,
  75% {
    opacity: 0;
  }
}


@media (prefers-reduced-motion: no-preference) {
  .rotate {
    animation: App-logo-spin infinite 20s linear;
  }
}

@keyframes App-logo-spin {
  from {
    transform: rotate(0deg);
  }
  to {
    transform: rotate(360deg);
  }
}



@keyframes slide-in {
  0%   { opacity: 0; transform: none }
  100% { opacity: 1; transform: none; }
}

.question {
  display: none;
  animation: slide-in .4s ease;
}

.question:first-child {
  display: block;
}

.results-container {
  width: 100%;
  margin: 0 auto;
  display: flex;
  align-items: center;
  justify-content: center;
  flex-direction: column;
  height: 100%;

  line-height: 1.75em;
  animation: slide-in .4s ease;
}

.results-total {
  margin-top: 25px;

}

.results-container a {
  position: relative;
  padding: 15px 30px;
  margin-top: 30px;
  border: 3px solid #111;
  background: none;
  cursor: pointer;
  font-size: .75em;
  transition: background .2s;
}

.results-container a:hover {
  background: rgba(255,255,255,.1);
}

.results-container a:active, {
  background: rgba(255,255,255,.5);
  top: -2px;
}

.wrong {
  background: rgba(236,100,75,.5);
  animation: shake 0.5s cubic-bezier(.35,.05,.20,.99) both;
}

.right {
  background: rgba(135,211,124,.5);
}

@keyframes shake {
  10%, 90% {
    transform: translateX(-1px);
  }
  20%, 80% {
    transform: translateX(1px);
  }
  30%, 50%, 70% {
    transform: translateX(-2px);
  }
  45%, 55% {
    transform: translateX(2px);
  }
}

.correct-modal {
  text-align: center;
  font-size: 4em;
  color: #ffffff;
  background: #2E3B55;
  padding: 5%;
  z-index: 2;
  opacity: 1;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  height: 350px;
  overflow: auto;
  margin: auto;
  position: absolute;
  top: 0;
  left: 0;
  bottom: 0;
  right: 0;
  backface-visibility: hidden;
  -webkit-font-smoothing: antialiased;
  display: none;

}

.correct-modal.modal-enter {
  display: flex;
  font-size: 4em;
  animation:  tracking-in-contract   .6s ease-in ;
}

.praise, .points {
  backface-visibility: hidden;
  -webkit-font-smoothing: antialiased;

}

@keyframes modal-enter {
  0 {
    visibility: visible;
    opacity: 0;
  }
  25% {
    transform: scale(1);
    opacity: .4;
  }
  85% {
    opacity: 1;
    transform: scale(1);
  }
  99% {
    opacity: .5;
    transform: scale(1);
  }
  100% {
    display: none;
    transform: scale(1);
  }
}

@media (min-width: 600px) {
  body {
    font-size: 12px;
  }
}

@media (min-width: 900px) {
  body {
    font-size: 14px;
  }

  h1 {
    font-size: 3em;
  }

/*  header {
    padding: 50px 0 30px;

  }*/

  .questions {
    width: 70%;
  }

  .question-answer:hover {
    border-color: rgba(0,0,0,.5);
  }

  .question-answer:focus {
    outline: gray solid 1px;
  }

  .correct-modal {
    height: 300px;
  }
}

@media (min-width: 1400px) {
  body {
    font-size: 16px;
  }

  .correct-modal {
    height: 400px;
  }
}

@media (min-width: 1600px) {
  body {
  /*  overflow: hidden; */
  }
}
